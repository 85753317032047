import React, { useEffect } from "react"
import TextBlock from "./blocks/text-block"
import ImageBlock from "./blocks/image-block"
import VideoBlock from "./blocks/video-block"

export default function NewsFlexibleContent({blocks}) {
  console.log(blocks)

  useEffect(() => {
    const header = document.getElementById("main-header");
    if (header !== null) {
      let headerHeight = header.clientHeight
      document.body.style.paddingTop = `${headerHeight}px`
    }
  }, [])

  if (blocks) {
    return blocks.map((block, i) => {
      console.log(block.__typename)
      switch (block.__typename) {
      
        /* -- ACF BLOCKS -- */
        case 'WpNewsArticle_Acfnewsarticle_NewsFlexibleContent_TextBlock':
          return <TextBlock data={block} key={i} />

        case 'WpNewsArticle_Acfnewsarticle_NewsFlexibleContent_Image':
          return <ImageBlock data={block} key={i} />

        case 'WpNewsArticle_Acfnewsarticle_NewsFlexibleContent_Video':
          return <VideoBlock data={block} key={i} />
          
        default:
         return <pre key={i}>{JSON.stringify(block, null, 2)}</pre>
      }
    })
  } else return null
}