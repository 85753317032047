import React, { useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { gsap } from 'utils/gsap'

const VideoBlock = ({ data }) => {
  const { video } = data;
  const videoWithTextRef = useRef();
  const tl = useRef();
  useEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap.timeline({
        duration: .4,
        scrollTrigger: {
          trigger: videoWithTextRef.current,
        }
      })
        .from('.text', {
          opacity: 0,
          x: '-100px',
          stagger: 0.25
        })
        .from('.video', {
          opacity: 0,
          x: '-100px',
          stagger: 0.25
        }, "<");
    }, videoWithTextRef);
  }, []);
  return (
    <div className={`relative`}>
      <div ref={videoWithTextRef} className="relative z-20 pb-8 md:flex md:items-center md:flex-row md:justify-center">
        {video && (
          <div className={`video aspect-video w-full`}>
            <ReactPlayer
              url={video}
              config={{
                youtube: {
                  playerVars: {
                    // https://developers.google.com/youtube/player_parameters
                    autoplay: 0,
                    controls: 0,
                    autohide: 1,
                    rel: 0,
                    modestbranding: 1,
                    loop: 1,
                    mute: 1,
                    fs: 0,
                    playsinline: 1,
                  },
                  // onUnstarted: onVideoError
                },
              }}
              controls={false}
              className=""
              width="100%"
              height="100%"
              muted={true}
              loop={true}
              playsinline={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoBlock