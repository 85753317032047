import React, { useEffect, useRef } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { gsap } from 'utils/gsap'

export default function ImageBlock({data}) {
  const { image } = data
  const imgRef = useRef();
  const tl = useRef();

  useEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap.timeline({
        duration: .4,
        scrollTrigger: {
          trigger: imgRef.current,
          toggleActions: "play none none none",
          start: "top 75%"
          // start: "top top"
        }
      })
        .from(imgRef.current, {
          opacity: 0,
          x: '-100px',
          stagger: 0.25
        });
    }, imgRef);
  }, []);

  if (image) {
    return(
      <div className="pb-8" ref={imgRef}>
        <GatsbyImage
          image={image.localFile.childImageSharp.gatsbyImageData}
          alt={image.altText}
        />
        {image.caption && (
          <p>{image.caption}</p>
        )}
      </div>
    )
  }
  return null
}