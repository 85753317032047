import React, { useEffect, useRef } from "react"
import { filterCmsLinks } from "utils/helpers"
import { gsap } from 'utils/gsap'

const TextFullWidth = ({data}) => {
  const { text } = data
  const textFullWidthRef = useRef();
  const tl = useRef();
  useEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap.timeline({
        duration: .4,
        scrollTrigger: {
          trigger: textFullWidthRef.current,
          toggleActions: "play none none none",
          start: "top 75%"
          // start: "top top"
        }
      })
        .from(textFullWidthRef.current, {
          opacity: 0,
          x: '-100px',
          stagger: 0.25
        });
    }, textFullWidthRef);
  }, []);
  return(
    <div className="relative text-black">
      <div className={`pb-8`}>
        <div ref={textFullWidthRef} className="relative z-[20]">
          {text &&
            <div className={`[&>ul]:list-disc [&>ul]:pl-5 [&>ul]:pb-3 [&>ul>li]:py-1 [&>h3]:text-2xl [&>h3]:mb-0 [&>h3]:md:text-3xl [&>p]:xl:text-[20px] [&>ul>li]:xl:text-[20px] [&>h3]:xl:text-[40px] [&>p]:mb-4`} 
            dangerouslySetInnerHTML={{ __html: filterCmsLinks(text) }} 
          />
          }
        </div>
      </div>
    </div>
  )
}

export default TextFullWidth