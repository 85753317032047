import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "components/layout"
import NewsFlexibleContent from "components/news-flexible-content/news-flexible-content"
import NewsSidebar from "components/news-sidebar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons"

export default function PageTemplate({data}) {
  const { wpNewsArticle } = data

  return(
    <Layout data={wpNewsArticle}>
      <div className="bg-white text-black">
        <div className="max-w-main w-90 mx-auto flex flex-wrap justify-between pt-8 md:pt-16">
          <div className="basis-full lg:basis-8/12 lg:pr-8">
            <p className="flex pb-4"><Link to="/news/" className="block pb-4 text-purple">News</Link> &nbsp;&gt;&nbsp; {wpNewsArticle.title}</p>
            <h1 className="text-[25px] md:text-[35px] xl:text-[50px] leading-none">{wpNewsArticle.title}</h1>
            <NewsFlexibleContent blocks={wpNewsArticle?.acfNewsArticle?.newsFlexibleContent} />
            <Link to="/news/" className="text-black block pb-4"><FontAwesomeIcon icon={faAngleLeft} /> &nbsp; Back</Link>
          </div>
          <div className="basis-full sm:basis-6/12 lg:basis-4/12 xl:basis-3/12">
            <NewsSidebar sidebarBlocks={wpNewsArticle?.acfNewsArticle?.sidebarBlocks} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    wpNewsArticle(id: {eq: $id}) {
      title
      uri
      date
      content
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
        }
        breadcrumbs {
          text
          url
        }
        canonical
      }
      acfNewsArticle {
        newsFlexibleContent {
          __typename
          ... on WpNewsArticle_Acfnewsarticle_NewsFlexibleContent_TextBlock {
            text
          }
          ... on WpNewsArticle_Acfnewsarticle_NewsFlexibleContent_Image {
            image {
              altText
              caption
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 900, quality: 90, placeholder: NONE, layout: CONSTRAINED)
                }
              }
            }
          }
          ... on WpNewsArticle_Acfnewsarticle_NewsFlexibleContent_Video {
            video
          }
        }
        sidebarBlocks {
          title
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 496, quality: 90, placeholder: NONE, layout: CONSTRAINED)
              }
            }
          }
          link {
            url
            title
            target
          }
        }
      }
    }
  }
`