import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { FacebookShareButton, TwitterShareButton } from "react-share"
import NewsCard from "components/shared/news-card"
import { GatsbyImage } from "gatsby-plugin-image"
import CMSLink from "components/shared/cms-link"

export default function NewsSidebar(props) {
  const data = useStaticQuery(graphql`
    {
      allWpNewsArticle(limit: 2) {
        edges {
          node {
            title
            uri
            date
            acfNewsArticle {
              excerpt
              featuredImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData( quality: 100, placeholder: NONE, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
      allWp {
        edges {
          node {
            ...optionsFragment
          }
        }
      }
    }
  `)

  const sidebarBlocks = props.sidebarBlocks ? props.sidebarBlocks : data.allWp?.edges[0]?.node?.options?.acfOptions?.sidebarBlocks

  return(
    <div>
      <h3 className="px-4 pb-2 text-dark-blue">Share this article</h3>
      <div className="flex px-4 pb-8">
        <FacebookShareButton className="share-button d-inline-block mr-4" url={typeof window !== "undefined" ? window.location : ""}>
            <FontAwesomeIcon icon={faFacebookF} />
        </FacebookShareButton>
        <TwitterShareButton className="share-button d-inline-block" url={typeof window !== "undefined" ? window.location : ""}>
            <FontAwesomeIcon icon={faTwitter} />
        </TwitterShareButton>
      </div>

      {sidebarBlocks?.map((item, i) => {
        const Content = () => (
          <>
            <GatsbyImage
              image={item.image?.localFile?.childImageSharp?.gatsbyImageData}
              alt={item.image?.altText}
              className="max-w-full"
            />
            {item.title && (
              <div className="absolute top-1/2 -translate-y-1/2 text-center uppercase w-full px-4 left-0 text-white font-bold font-display">{item.title}</div>
            )}
          </>
        )
        if (item.link) {
          return(
            <CMSLink url={item.link.url} className="px-4 relative block mb-8" type="link" key={`sidebarblock${i}`}>
              <Content />
            </CMSLink>
          )
        } else {
          return(
            <div className="px-4 relative mb-8" key={`sidebarblock${i}`}>
              <Content />
            </div>
          )
        }
      })}

      <h3 className="px-4 text-dark-blue">Top Articles</h3>
      {data?.allWpNewsArticle?.edges.map(({node}, i) => {
        return <NewsCard data={node} displayExcerpt={false} />
      })}
    </div>
  )
}